import React from 'react'
import './InputButtonComponent.css'


const InputButtonComponent = ({black, center}) => {
    return (
        <div className={black ? "custom-input-black" : "custom-input-white"} style={{display: 'flex', justifyContent: center ? 'center' : 'left'}}>
            <a href='https://pixelmeform.typeform.com/to/yDCb7PnS'><button>SIGN UP FOR FREE</button></a>
        </div>
    )
}

export default InputButtonComponent
