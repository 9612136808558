import React from 'react'
import InputButtonComponent from '../../component/InputButtonComponent/InputButtonComponent'
import './LandingMobilePage.css'
import typingImageMobile from '../../assets/mobile/typing_screen_mobile.svg'
import whitePixel from '../../assets/white_pixel.svg'
import previewHomeMobile from '../../assets/mobile/2.png'
import extensionImgMobile from '../../assets/mobile/3.png'
import twitterIcon from '../../assets/twitter_icon.svg'
import facebookIcon from '../../assets/facebook_icon.svg'
import logo from '../../assets/logo.svg'
import PrincingComponent from '../../component/PricingComponent/PrincingComponent'


const LandingMobilePage = () => {
    return (
        <div className="landing-mobile">
            {/* firstpage */}
            <section className="firstpage-mobile">
                <div className="firstpage-mobile__titleside">
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2rem'}}>
                        <img alt='logo' src={logo}/>
                    </div>
                    <h1>Type 10X faster without any efforts.</h1>
                    <p>Create snippets with your most common sentences, start typing naturally and <strong> we do the rest.</strong></p>
                    <img style={{marginTop: '2rem'}} src={typingImageMobile} alt='imagetyping'/>
                    <img className="pixel_img_mobile" src={whitePixel} alt='imagepixel'/>
                    <InputButtonComponent center={true}/>
                    <span style={{marginTop: '2rem'}}>No promotional email, just a quick reminder to try it later when you’ll be on your computer.</span>
                </div>
            </section>
            {/* firstpage-mobile */}

            {/* details page */}
            <section className="details-mobile">
                <div className="details-mobile__textside">
                    <h2>Save your most common text, reply, sentences and use them faster!</h2>
                    <div className="detail">
                        <h3>Shortcuts</h3>
                        <p>Expand your texts and snippets with your own shortcuts, that'll make your life easier (and faster).</p>
                    </div>
                    <div className="detail">
                        <h3>Smart composer</h3>
                        <p>Our smart composer will display your snippets based on what you are typing. Don't need to memorize again all your shortcuts, start typing, we do the rest!</p>
                    </div>
                </div>
                <div className="details-mobile__imgside">
                    <img style={{width:'358px'}} alt='previewHomeMobile' src={previewHomeMobile}/>
                </div>
            </section>
            {/* details page */}

            {/* extension page */}
            <section className="extension-mobile">
                <div className="extension-mobile__textside">
                    <h2>Get our Chrome extension</h2>
                    <p>Manage, edit and create your shortcuts directly from the Chrome extension. All your shortcuts are saved on your Chrome to unlock the faster experience.</p>
                    <p>Quick add a new shortcut without moving from your page!</p>
                    <div>
                        <img style={{width: '100%'}} alt='extensionImgMobile' src={extensionImgMobile}/>
                    </div>
                    <InputButtonComponent black={true} center={true}/>
                </div>
            </section>
            {/* extension page */}

            {/* pricingpage */}
            <section className="pricing-mobile">
                <div className="pricing-mobile__text-container">
                    <h2>Our simple pricing</h2>
                    <p>We decided to do something simple, only one free & paid plan with everthing unlimited</p>
                </div>
                <PrincingComponent/>
            </section>
            {/* pricingpage */}

            {/* footer-mobile */}
            <section className="footer-mobile">
                <img alt='logo' src={logo}/>
                <div className="footer-mobile-container">
                    <p>Typisty is the most powerful tool for a smart composition with pre-saved shortcuts.</p>
                    <p>Use it everywhere in your Chrome and increase your productivity.</p>
                    <div className="contact-us">
                        <span><strong>Contact us</strong></span>
                        <span>hello@typisty.com</span>
                        <div>
                            <img alt='twitterIcon' src={twitterIcon}/>
                            <img alt='facebookIcon' src={facebookIcon}/>
                        </div>
                    </div>
                </div>
                <div className="footer-mobile-right-container">
                    <p>© 2020 Typisty. All Rights Reserved.</p>
                </div>
            </section>
            {/* footer-mobile */}
        </div>
    )
}

export default LandingMobilePage