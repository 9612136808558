import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import TermsPage from './pages/TermsPage/TermsPage';

function App() {
  return (
    <>
    <Router>
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/terms_privacy' component={TermsPage} />
      </Switch>
    </Router>
    </>
  );
}

export default App;
