import React from 'react'
import './HeadBarComponent.css'
import logo from '../../assets/logo.svg'
import logoDark from '../../assets/logo_dark.svg'
import { useHistory } from 'react-router'


const HeadBarComponent = ({style, home, dark}) => {
    const history = useHistory();
    return (
        <div className='headbar' style={style}>
            <img alt='logo head' src={dark ? logo : logoDark}/>
            <div>
                {home ? <></> : <span className='home-btn' onClick={() => {history.push('/')}}>Home</span>}
                <a href='https://pixelmeform.typeform.com/to/yDCb7PnS'><button className={dark ? 'btn-head' : 'btn-head-white'}>Login</button></a>
            </div>
        </div>
    )
}

export default HeadBarComponent
