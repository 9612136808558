import React, { useEffect } from 'react'
import InputButtonComponent from '../../component/InputButtonComponent/InputButtonComponent'
import './LandingPage.css'
import typingImage from '../../assets/typing_screen.svg'
import whitePixel from '../../assets/white_pixel.svg'
import previewHome from '../../assets/preview_home.svg'
import extensionImg from '../../assets/extension_img.svg'
import appIcon from '../../assets/app-icon.svg'
import PrincingComponent from '../../component/PricingComponent/PrincingComponent'
import LandingMobilePage from '../LandingMobilePage/LandingMobilePage'
import HeadBarComponent from '../../component/HeadBarComponent/HeadBarComponent'
import FooterComponent from '../../component/FooterComponent/FooterComponent'

function useWindowSize() {

    const isClient = typeof window === 'object';
    const [windowSize, setWindowSize] = React.useState(getSize);

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

const LandingPage = () => {
    const size = useWindowSize();

    useEffect(() => {
        if (!localStorage.getItem('r2xdvisited')) {
            localStorage.setItem('r2xdvisited', true);
        }
    }, [])

    return (
        <>
            { size.width < 1000 ? <LandingMobilePage></LandingMobilePage> :
                <div className="landing">
                    <HeadBarComponent dark={true} />
                    {/* firstpage */}
                    {
                        !localStorage.getItem('r2xdvisited') ?
                            <section className="firstpage">
                                <div className="firstpage__titleside">
                                    <h1>This is the new way of typing.</h1>
                                    <h2 className='typingtext'>Imagine starting typing like this, and</h2>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h2 className='typingtext__second'>your text</h2>
                                        <span className="typingtext__third">appears like that.</span>
                                    </div>
                                    <p className="fade_in_up">Create snippets with your most common sentences, start typing naturally and we do the rest. <strong>Type 10X faster without any efforts.</strong></p>
                                    <div className="fade_in_up">
                                        <InputButtonComponent />
                                    </div>
                                </div>
                                <div className="firstpage__imgside">
                                    <img className="fade_in_up" src={typingImage} alt='imagetyping' />
                                    <img className="pixel_img" src={whitePixel} alt='imagepixel' />
                                </div>
                            </section>
                            :
                            <section className="firstpage">
                                <div className="firstpage__titleside">
                                     <h1 style={{marginBottom: '0px' }}>This is the new way of typing.</h1>
                                    <h2>Type 10x faster without any efforts</h2>
                                    <p>Create snippets with your most common sentences, start typing naturally and we do the rest.</p>
                                    <div>
                                        <InputButtonComponent />
                                    </div>
                                </div>
                                <div className="firstpage__imgside">
                                    <img  src={typingImage} alt='imagetyping' />
                                    <img className="pixel_img" src={whitePixel} alt='imagepixel' />
                                </div>
                            </section>
                    }
                    {/* firstpage */}

                    {/* details page */}
                    <section className="detailspage">
                        <div className="detailspage__textside">
                            <h2>Save your most common text, reply, sentences and use them faster!</h2>
                            <div className="detail">
                                <h3>Shortcuts</h3>
                                <p>Expand your texts and snippets with your own shortcuts, that'll make your life easier (and faster).</p>
                            </div>
                            <div className="detail">
                                <h3>Smart composer</h3>
                                <p>Our smart composer will display your snippets based on what you are typing. Don't need to memorize again all your shortcuts, start typing, we do the rest!</p>
                            </div>
                        </div>
                        <div className="detailspage__imgside">
                            <img alt='previewHome' src={previewHome} />
                        </div>
                    </section>
                    {/* details page */}

                    {/* extension page */}
                    <section className="extensionpage">
                        <div className="extensionpage__imgside">
                            <img alt='extensionImg' src={extensionImg} />
                        </div>
                        <div className="extensionpage__textside">
                            <h2>Get our Chrome extension</h2>
                            <p>Manage, edit and create your shortcuts directly from the Chrome extension. All your shortcuts are saved on your Chrome to unlock the faster experience.</p>
                            <p>Quick add a new shortcut without moving from your page!</p>
                            <InputButtonComponent black={true} />
                        </div>
                    </section>
                    {/* extension page */}

                    {/* app page */}
                    <section className="app-page">
                        <div className="app-page__text-container">
                            <h2>Works everywhere on your Google Chrome</h2>
                            <p>Use your snippets across all major websites. Doing customer support, emails, documents, social media and many more like a boss!</p>
                        </div>
                        <img alt='appicon' src={appIcon} />
                    </section>
                    {/* app page */}

                    {/* pricingpage */}
                    <section className="pricingpage">
                        <div className="pricingpage__text-container">
                            <h2>Our simple pricing</h2>
                            <p>We decided to do something simple, only one free & paid plan with everthing unlimited</p>
                        </div>
                        <PrincingComponent />
                        <InputButtonComponent black={true} />
                    </section>
                    {/* pricingpage */}

                    {/* footer */}
                    <FooterComponent/>
                    {/* footer */}
                </div>
            }
        </>
    )
}

export default LandingPage
