import React from 'react'
import './FooterComponent.css'
import logo from '../../assets/logo.svg'
import twitterIcon from '../../assets/twitter_icon.svg'
import facebookIcon from '../../assets/facebook_icon.svg'
import { useHistory } from 'react-router'


const FooterComponent = () => {
    const history = useHistory();
    return (<section className="footer">
        <img alt='logo' src={logo} />
        <div className="footer-container">
            <div style={{ width: '28%' }}>
                <p>Typisty is the most powerful tool for a smart composition with pre-saved shortcuts.</p>
                <p>Use it everywhere in your Chrome and increase your productivity.</p>
            </div>
            <div className="contact-us">
                <span><strong>Contact us</strong></span>
                <span>hello@typisty.com</span>
                <span style={{cursor: 'pointer'}} onClick={() => history.push('/terms_privacy')}>Terms & privacy</span>
                <div>
                    <img alt='twitter' src={twitterIcon} />
                    <img alt='face book' src={facebookIcon} />
                </div>
            </div>
        </div>
        <div className="footer-right-container">
            <p>© 2020 Typisty. All Rights Reserved.</p>
        </div>
    </section>
    )
}

export default FooterComponent