import React, { useState } from 'react'
import Toggle from 'react-toggle';
import './PrincingComponent.css'
import validatedIcon from '../../assets/validated_icon.svg'


const PrincingComponent = () => {
    const [isMonthly, setIsMonthly] = useState(false);
    const handleToggle = () => {
        setIsMonthly(!isMonthly)
    }
    return (
        <div className="pricing">
            <div className="toogle-container">
                <span>Monthly</span>
                <div style={{ marginRight: '1rem', marginLeft: '1rem' }}>
                    <Toggle
                        defaultChecked={isMonthly}
                        icons={false}
                        onChange={handleToggle}
                    />
                </div>
                <div style={{ display: 'flex', position: 'relative' }}>
                    <span style={{ marginRight: '0.5rem' }}>Annually</span>
                    <div className="percent-box">
                        -40%
                    </div>
                </div>
            </div>

            <div className="price-container"> {!isMonthly ?
                <>
                    <div className="pricing-card" style={{ marginRight: "1rem" }}>
                        <h3>Free</h3>
                        <p><span>$0</span>/month</p>
                        <div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p><strong>10</strong> snippets</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Chrome extension</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Smart composer</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Shortcuts</p>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-card" style={{ marginLeft: "1rem" }}>
                        <h3>Premium</h3>
                        <p><span>$2.99</span>/month</p>
                        <div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p><strong>Unlimited</strong> snippets</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Chrome extension</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Smart composer</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Shortcuts</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Team members</p>
                            </div>
                        </div>
                    </div>
                </> :
                <>
                    <div className="pricing-card" style={{ marginRight: "1rem" }}>
                        <h3>Free</h3>
                        <p><span>$0</span>/yearly</p>
                        <div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p><strong>10</strong> snippets</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Chrome extension</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Smart composer</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Shortcuts</p>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-card" style={{ marginLeft: "1rem" }}>
                        <h3>Premium</h3>
                        <p><span>$30</span>/yearly</p>
                        <div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p><strong>Unlimited</strong> snippets</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Chrome extension</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Smart composer</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Shortcuts</p>
                            </div>
                            <div className="features">
                                <img src={validatedIcon} alt='valided icon' />
                                <p>Team members</p>
                            </div>
                        </div>
                    </div>
                </>
            }
            </div>
        </div>
    )
}

export default PrincingComponent