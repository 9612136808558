import React from 'react'
import FooterComponent from '../../component/FooterComponent/FooterComponent'
import HeadBarComponent from '../../component/HeadBarComponent/HeadBarComponent'
import './TermsPage.css'


const TermsPage = () => {
    return (
        <>
        <HeadBarComponent style={{backgroundColor: 'transparent'}}/>
        <div className='terms'>
            <h1>Terms & Privacy Policy</h1>
            <div>
                <h2>Company</h2>
                <p>Typisty by PixelMe SAS <br></br>Cap Omega <br></br>Rond-point Benjamin Franklin <br></br>34970 Montpellier - France </p>
                <p>You can reach us via email: team@pixelme.me</p>
            </div>
            <div className='terms__section'>
                <h2>Terms</h2>
                <p>1. Terms By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.</p>
                <p>2. Use License Permission is granted to temporarily download one copy of the materials (information or software) on Typisty’s web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or non-commercial); attempt to decompile or reverse engineer any software contained on PixelMe's web site; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any other server. This license shall automatically terminate if you violate any of these restrictions and may be terminated by PixelMe at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
                <p>3. Disclaimer The materials on PixelMe's web site are provided "as is". PixelMe makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, PixelMe does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.</p>
                <p>4. Limitations In no event shall PixelMe or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on PixelMe's Internet site, even if PixelMe or a PixelMe authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                <p>5. Revisions and Errata The materials appearing on PixelMe's web site could include technical, typographical, or photographic errors. PixelMe does not warrant that any of the materials on its web site are accurate, complete, or current. PixelMe may make changes to the materials contained on its web site at any time without notice. PixelMe does not, however, make any commitment to update the materials.</p>
                <p>6. Links PixelMe has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by PixelMe of the site. Use of any such linked web site is at the user's own risk.</p>
                <p>7. Site Terms of Use Modifications PixelMe may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>
                <p>8. Governing Law Any claim relating to Typisty’s web site shall be governed by the laws of the State of Delaware without regard to its conflict of law provisions. General Terms and Conditions applicable to Use of a Web Site.</p>
                <p>9. All Content added, created, uploaded, submitted, distributed, or posted to the Services by users, whether publicly posted or privately transmitted, is the sole responsibility of the user who originated it. You acknowledge that all Content accessed by you using the Services is at your own risk and you will be solely responsible for any damage or loss to you or any other party resulting therefrom. When you delete your User Content, it will be removed from the Services. However, you understand that certain User Content (e.g., previously shortened URLs and related PixelMe Metrics) will remain available and any removed User Content may persist in backup copies for a reasonable period of time (but will not following removal be shared with others).</p>
                <p>10. The Services may permit you to link to other websites, services or resources on the Internet, websites, services or resources may contain links to the Services. When you access third party resources on the Internet, you do so at your own risk. These other resources are not under the control of PixelMe, and you acknowledge that PixelMe is not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources. The inclusion of any such link does not imply endorsement by PixelMe or any association with its operators. You further acknowledge and agree that PixelMe shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such Content, goods or services available on or through any such website or resource.</p>
                <p>11. With regard to content hosted on PixelMe's servers, it is PixelMe's policy to block access to or remove material that it believes in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users; and remove account.</p>
                <p>12. If you receive what you believe to be a phishing email with PixelMe URLs inside, please send it by email. We'll delete immediately URLs and accounts from those people: team@pixelme.me</p>
                <p>13. GDPR compliant feature: PixelMe provides a simple and easy to use mechanism for collecting European visitors consents every-time they click on any of shorten links.</p>
                <p>13.1. The GDPR compliant feature is only based on some basic requirements in the GDPR, it is therefore not a complete guarantee requirements set out in the GDPR.</p>
                <p>13.2. PixelMe is designed as a service to facilitate compliance with EU regulations concerning the use of cookies on your website and to help you obtain the relevant consents to the use of cookies from the users of your website. PixelMe is only the service provider of the IT infrastructure, and it is your own responsibility to ensure correct implementation and provide relevant input to the system. The relevant EU legislation is not necessarily implemented in the same way in all EU countries, and we cannot guarantee that using PixelMe will automatically lead to compliance with all relevant rules and regulations concerning the use of cookies or the collection of consents to the use of cookies. We encourage you to seek local legal advice to ensure compliance with local legislation when implementing the solution on your website and to tailor the wording of the consents to be shown on your website.</p>
                <p>13.3. By using our website or services, you understand and agree that our GDPR compliant feature we provide is optional. This means that we do not represent or warrant to you that: a. the use of our GDPR compliant feature will meet your needs or requirements; b. the use of our GDPR compliant feature will be uninterrupted, timely, secure or free from errors; c. the information obtained by using our GDPR compliant feature will be accurate or reliable; nor that d. any defects in the operation or functionality of any GDPR compliant feature we provide will be repaired or corrected.</p>
                <p>13.4. Unless otherwise expressed, PixelMe expressly disclaims all warranties, guarantee and conditions of any kind for the GDPR compliant feature, whether express or implied, including but not limited to any implied warranties, guarantee and conditions of merchantability, fitness for a particular purpose and non-infringement</p>
                <p>14. Unless an Order Form says something difsferent, (a) all subscriptions automatically renew for additional periods equal to one (1) year or the preceding term, whichever is shorter; and (b) the per-unit pricing during any automatic renewal term will remain the same as it was during the immediately prior term. Either party can give the other notice of non-renewal at least thirty (30) days before the end of a subscription term to stop the subscriptions from automatically renewing.</p>
                <p>15. For a monthly subscription: a refund can be asked only if the refund is requested within 3 days of payment AND if there has been no activity on your account in that time and if is not the first month of the subscription.</p>
                <p>15.1 For a yearly subscription: a refund can be asked only if the refund is requested within 10 days of payment AND if there has been no activity on your account in that time and if is not the first year of the subscription.</p>
            </div>
            <div className='terms__section'>
                <h2>Privacy Policy</h2>
                <p>Our Privacy Policy was posted on 10 May 2018. It governs the privacy terms of our website, located at typisty.com, and the tools we provide you (the "Website" or the "Service").</p>
                <p>Your Privacy</p>
                <p>Our Website follows all legal requirements to protect your privacy. Our Privacy Policy is a legal statement that explains how we may collect information from you, how we may share your information, and how you can limit our sharing of your information. You will see terms in our Privacy Policy that are capitalized. These terms have meanings as described in the Definitions section below.</p>
                <p>Definitions
                - Personal Data: Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                - Usage Data: Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).- Cookies: Cookies are small pieces of data stored on a User's device.<br></br>- Data Controller: Data Controller means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.- Data Processors (or Service Providers): Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.- Data Subject: Data Subject is any living individual who is the subject of Personal Data.<br></br>- User: The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data. Information Collection And Use</p>
                <p>We collect several different types of information for various purposes to provide and improve our Service to you. Types of Data Collected</p>
                <p>Personal Data<br></br>- While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to: Email address, Name, Address, State, Province, ZIP/Postal code, City, Cookies and Usage Data.<br></br>- We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>
                <p>Usage Data<br></br>- We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                <p>Tracking & Cookies Data<br></br>- We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.<br></br>- Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.<br></br>
                - You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.<br></br>
                - Examples of Cookies we use: session cookies (we use these cookies to operate our Service) and preference cookies (we use these cookies to remember your preferences and various settings).
                </p>
                <p>
                    Use of Data<br></br>
                    We use the collected data for various purposes:<br></br>
                    - To provide and maintain our Service<br></br>
                    - To notify you about changes to our Service<br></br>
                    - To allow you to participate in interactive features of our Service when you choose to do so<br></br>
                    - To provide customer support<br></br>
                    - To gather analysis or valuable information so that we can improve our Service<br></br>
                    - To monitor the usage of our Service<br></br>
                    - To detect, prevent and address technical issues<br></br>
                    - To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information Retention of Data
                </p>
                <p>
                    We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.<br></br>
                    We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
                </p>
                <p>
                Transfer Of Data<br></br>
                Your information, including Personal Data, may be transferred to â€” and maintained on â€” computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.<br></br>
                We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.<br></br>
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                </p>
                <p>
                Disclosure Of Data
                - Business Transaction. If we are involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.
                - Disclosure for Law Enforcement. Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). Security Of Data
                The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)
                If you are from the European Economic Area (EEA), PixelMe SAS legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it. PixelMe SAS may process your Personal Data because:<br></br>
                :
                - We need to perform a contract with you <br/>
                - You have given us permission to do so - The processing is in our legitimate interests and it's not overridden by your rights<br/>
                - For payment processing purposes<br/>
                - To comply with the law<br/>
                </p>
                <p>Your Data Protection Rights Under General Data Protection Regulation (GDPR) <br/>
                If you are a resident of the European Economic Area (EEA), you have certain data protection rights. PixelMe SAS aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. <br/>
                If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
                <p>In certain circumstances, you have the following data protection rights: <br/>
                - The right to access, update or to delete the information we have on you.<br/>
                - The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.<br/>
                - The right to object. You have the right to object to our processing of your Personal Data.<br/>
                - The right of restriction. You have the right to request that we restrict the processing of your personal information.<br/>
                - The right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format. <br/>
                - The right to withdraw consent. You also have the right to withdraw your consent at any time where PixelMe SAS relied on your consent to process your personal information.
                </p>
                <p>Please note that we may ask you to verify your identity before responding to such requests. You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
                <p>Service Providers We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.<br/>
                These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                <p>Analytics <br/>
                We may use third-party Service Providers to monitor and analyze the use of our Service.<br/>
                - Google Analytics<br/>
                - Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.<br/>
                - You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.<br/>
                - For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: http://www.google.com/intl/en/policies/privacy/</p>
                <p>Payments  <br/>
                    We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors). <br/>
                    We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information. <br/>
                    The payment processors we work with are: <br/>
                    - Stripe. Their Privacy Policy can be viewed at https://stripe.com/privacy Links To Other Sites</p>
                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. <br/>
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                <p>
                Children's Privacy <br/>
                Our Service does not address anyone under the age of 18 ("Children"). <br/>
                We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                </p>
                <p>Changes To This Privacy Policy <br/>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy. <br/>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                </p>
                <p>Contact Us If you have any questions about this Privacy Policy, please contact us at team@pixelme.me</p>
            </div>
            <div className='terms__section' style={{marginBottom: '5rem'}}>
                <h2>Cookies Policy</h2>
                <p>Last updated: 10 May 2018</p>
                <p>PixelMe SAS uses cookies on www.typisty.com (the "Service"). By using the Service, you consent to the use of cookies. Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.</p>
                <p>What are cookies: <br/>
                    Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you. Cookies can be "persistent" or "session" cookies.
                </p>
                <p>How PixelMe SAS uses cookies: <br/>
                    When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences, to enable advertisements delivery, including behavioral advertising. We use both session and persistent cookies on the Service and we use different types of cookies to run the Service: - Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.
                </p>
                <p>This website uses cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. You consent to our cookies if you continue to use our website.</p>
                <p>Cookies are small text files that can be used by websites to make a user's experience more efficient.</p>
                <p>The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.</p>
                <p>This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.</p>
                <p>You can at any time change or withdraw your consent from the Cookie Declaration on our website.</p>
                <p>Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.</p>
                <p>Your consent applies to the following domains: pixelme.me</p>
            </div>
        </div>
        <FooterComponent/>
        </>
    )
}

export default TermsPage